/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentGlobal: null,
    currentPath: null,
    categoryId: null,
    categoryName: null,
    productContacts: null
  },
  mutations: {
    setCurrentGlobal(state, value){
      sessionStorage.setItem('currentGlobal', value);
      state.currentGlobal = value;
    },
    setCurrentPath(state, value){
      sessionStorage.setItem('currentPath', value);
      state.currentPath = value;
    },
    setCategoryId(state, value){
      sessionStorage.setItem('categoryId', value);
      state.categoryId = value;
    },
    setCategoryName(state, value){
      sessionStorage.setItem('categoryName', value);
      state.categoryName = value;
    },
    setProductContacts(state, value){
      sessionStorage.setItem('productContacts', value);
      state.productContacts = value;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getCurrentGlobal: state => { return state.currentGlobal ? state.currentGlobal : (sessionStorage.getItem('currentGlobal') ? sessionStorage.getItem('currentGlobal') : 'home'); },
    getCurrentPath: state => { return state.currentPath ? state.currentPath : (sessionStorage.getItem('currentPath') ? sessionStorage.getItem('currentPath') : '/'); },
    getCategoryId: state => { return state.categoryId ? state.categoryId : sessionStorage.getItem('categoryId'); },
    getCategoryName: state => { return state.categoryName ? state.categoryName : sessionStorage.getItem('categoryName'); },
    getProductContacts: state => { return state.productContacts ? JSON.parse(state.productContacts) : (sessionStorage.getItem('productContacts') ? JSON.parse(sessionStorage.getItem('productContacts')) : null); }
  }
});
