var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerImage",style:({
        'background-image':
          'url(' + require('../assets/img/banner/alfalaval.jpg') + ')',
      })},[_vm._m(0)])]),_c('div',{staticClass:"cardList mt-4 mt-md-5 mt-lg-5 pt-lg-3"},[_c('div',{staticClass:"containerLarge"},[_vm._m(1),_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3"},[_c('div',{staticClass:"cardImage",on:{"click":function($event){return _vm.toGlobal('/marine', 'marine')}}},[_c('div',{staticClass:"cardBox",style:({
                'background-image':
                  'url(' +
                  require('../assets/img/banner/navi.jpg') +
                  ')',
              })}),_vm._m(2)])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3"},[_c('div',{staticClass:"cardImage",on:{"click":function($event){return _vm.toGlobal('/energy', 'energy')}}},[_c('div',{staticClass:"cardBox",style:({
                'background-image':
                  'url(' +
                  require('../assets/img/banner/energy.jpg') +
                  ')',
              })}),_vm._m(3)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerLarge py-5 px-2 p-sm-3 p-md-3 p-lg-4 p-xl-4"},[_c('div',{staticClass:"bannerImage-card p-2 p-sm-3 p-md-3 p-lg-4 p-xl-5 text-center col-md-12"},[_c('h1',[_vm._v("Getting Excellence")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mb-3 mb-md-5"},[_c('p',{staticClass:"lead text-center"},[_vm._v(" Alfa Laval is a leading global provider of specialized products and engineering solutions based on its key technologies of heat transfer, separation and fluid handling. The company’s equipment, systems and services are dedicated to assisting customers in optimizing the performance of their processes. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardContent"},[_c('h2',{staticClass:"cardContent-title"},[_vm._v("Marine")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardContent"},[_c('h2',{staticClass:"cardContent-title"},[_vm._v("Energy")])])])
}]

export { render, staticRenderFns }