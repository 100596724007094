<template>
  <div id='app'>
    <div id='nav'>
      <div id='mobileIos' class='installApp bgTurquoise'>
        <div class='containerLarge'>
          <div class='row align-items-center justify-content-between'>
            <div class='col-md-12 pt-2 pb-2'>
              <a class='text-white linkModal' @click="$bvModal.show('mobileIosModal')">
                Add the App on the screen for free
              </a>
              <button id='closeMobileIos' type='button' class='close text-white' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-modal  id='mobileIosModal' title='Add the App at home screen'
        hide-footer>
          To add the app to the home screen, click on the icon
          <img style='height:20px!important;' src='./assets/img/icon/share.svg' alt='' /> and
          click on <strong>Add to Home</strong>
      </b-modal>
      <div id='containerInstallApp' class='installApp bgTurquoise'>
        <div class='containerLarge'>
          <div class='row align-items-center justify-content-between'>
            <div class='col-md-12 pt-2 pb-2'>
              <a id='installApp' type='button' class='text-white buttonApp linkModal'
                >Add the App for free</a
              >
              <button
                id='closeContainerInstallApp'
                type='button'
                class='close text-white'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <section class='header'>
        <div class='containerLarge'>
          <b-navbar class="px-0" toggleable="lg" type="light">
            <b-navbar-brand class="linkClick" @click="toGlobal('/', 'home')">
              <img src='./assets/img/logo/logo-alfalaval.svg' alt='Alfa Laval Logo' />
            </b-navbar-brand>
            <b-navbar-toggle target="navbar-toggle-collapse">
              <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="x" font-scale="2" style="color: #1e1c77;"></b-icon>
                <b-icon v-else icon="list" font-scale="2" style="color: #1e1c77;"></b-icon>
              </template>
            </b-navbar-toggle>
            <b-collapse id="navbar-toggle-collapse" is-nav class="mt-3 mt-md-0">
              <!-- MENU HOME PAGE (solo per la route della home)-->
              <b-navbar-nav v-if="getCurrentGlobal === 'home'" class="ml-auto" right>
                <b-button class='nav-link' @click="toGlobal('/marine', 'marine')">Marine</b-button>
                <b-button class='nav-link' @click="toGlobal('/energy','energy')">Energy</b-button>
              </b-navbar-nav>
              <!-- MENU ENERGY (solo per la route della homeEnergy e contactsEnergy) -->
              <b-navbar-nav v-if="getCurrentGlobal === 'energy'" class="ml-auto" right>
                <b-button class='nav-link' @click="toGlobal('/energy','energy')">Energy</b-button>
                <b-button class='nav-link' @click="toExt('https://productguide.alfalaval.com')">Application</b-button>
                <b-button class='nav-link' @click="toGlobal('/energy-contacts','energy')">Contacts</b-button>
              </b-navbar-nav>
              <!-- MENU PER TUTTE LE PAGINE MARINE (per tutto il resto)-->
              <b-navbar-nav v-if="getCurrentGlobal === 'marine'" class="ml-auto" right>
                <b-button class='nav-link' @click="toGlobal('/marine', 'marine')">Marine</b-button>
                <b-button class='nav-link' @click="toGlobal('/products', 'marine')">Products</b-button>
                <b-button class='nav-link' @click="toGlobal('/size-your-system', 'marine')">Size your system</b-button>
                <b-button class='nav-link' @click="toGlobal('/marine-contacts', 'marine')">Contacts</b-button>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </section>
    </div>
    <!-- {{getCurrentPath}} e {{getCurrentGlobal}} -->
    <router-view />
    <section class='footer'>
      <div class='containerLarge'>
        <div class='row py-4'>
          <div class='col-sm-6 small text-center text-sm-left'>
            © 2020 ALFA LAVAL
          </div>
          <div class='col-sm-6 small text-center text-sm-right'>
            <ul class='list-inline mb-0'>
              <li class='list-inline-item'>
                <a class='linkEvidence linkClick iubenda-nostyle no-brand iubenda-embed' href="https://www.iubenda.com/privacy-policy/59891306" target="_blank" rel="nofollow noopener" title="Privacy Policy ">Privacy Policy</a>
              </li>
              <li class='list-inline-item'>
                <a class='linkEvidence linkClick iubenda-nostyle no-brand iubenda-embed' href="https://www.iubenda.com/privacy-policy/59891306/cookie-policy" target="_blank" rel="nofollow noopener" title="Cookie Policy ">Cookie Policy</a>
              </li>
              <li class='list-inline-item'>
                <a class='linkEvidence linkClick' href='https://www.alfalaval.com/legal/legal-terms-and-conditions/' target="_blank" rel="nofollow noopener">Legal terms and conditions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
  }),

  mounted() {
    let deferredPrompt;
    //container no ios
    const containerPromptButton = document.getElementById('containerInstallApp'); 
    const promptButton = document.getElementById('installApp'); //prompt scelta installazione

    const mobileIosAppv = document.getElementById('mobileIos'); //container
    const closeMobileIosA = document.getElementById('closeMobileIos'); //close container

    /*** MODALE PER IOS - SAFARI  ***/
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isSafari || (isSafari && iOS)) {
      containerPromptButton.style.display = 'none';
      promptButton.style.display = 'none';
      mobileIosAppv.style.display = 'block';

      closeMobileIosA.addEventListener('click', (e) => {
        console.log('ios close', e);
        mobileIosAppv.style.display = 'none';
      });
    } else {
      containerPromptButton.style.display = 'block';
      const closeContainerInstallApp = document.getElementById('closeContainerInstallApp');
      closeContainerInstallApp.addEventListener('click', (e) => {
        console.log('other close', e);
        containerPromptButton.style.display = 'none';
      });

      // prevent default install prompt
      if (promptButton) {
        containerPromptButton.style.display = 'none';
      }

      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        deferredPrompt = event;

        if (promptButton) {
          if (deferredPrompt) {
            // prompt has been requested
            containerPromptButton.style.display = 'inherit';
            promptButton.addEventListener('click', (e) => {
              // delegate the prompt to user action
              e.preventDefault();
              deferredPrompt.prompt();
              deferredPrompt = null;
            });
          }
        }
      });

      window.addEventListener('appinstalled', () => {
        // installed
        containerPromptButton.style.display = 'none';
        alert('App installata correttamente');
      });
    }
  },

  computed: {
    getCurrentGlobal(){
      return this.$store.getters.getCurrentGlobal;
    },
    
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
  },

  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    toExt(str){
      window.open(str);
    }
  }

};
</script>
<style></style>
