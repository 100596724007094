import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const resetHome = (to, from, next) => {
  store.commit('setCurrentGlobal', 'home');
  store.commit('setCurrentPath', '/');
  return next();
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: resetHome
  },
  {
    path: '/marine',
    name: 'Marine',
    component: () => import('../views/HomeMarine.vue'),
  },
  {
    path: '/energy',
    name: 'Energy',
    component: () => import('../views/HomeEnergy.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: () => import('../views/Category.vue'),
  },
  {
    path: '/model/:id',
    name: 'Model',
    component: () => import('../views/Model.vue'),
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('../views/Product.vue'),
  },
  {
    path: '/size-your-system/notfound',
    name: 'Sysnotfound',
    component: () => import('../views/SysNotFound.vue'),
  }, 
  {
    path: '/size-your-system/bwt',
    name: 'Sysbwt',
    component: () => import('../views/SysBwt.vue'),
  },
  {
    path: '/size-your-system/fwg',
    name: 'Sysfwg',
    component: () => import('../views/SysFwg.vue'),
  },
  {
    path: '/size-your-system/hss',
    name: 'Syshss',
    component: () => import('../views/SysHss.vue'),
  },
  {
    path: '/size-your-system/fcm',
    name: 'Sysfcm',
    component: () => import('../views/SysFcm.vue'),
  },
  {
    path: '/size-your-system/bws',
    name: 'Sysbws',
    component: () => import('../views/SysBws.vue'),
  },
  {
    path: '/size-your-system/phe',
    name: 'Sysphe',
    component: () => import('../views/SysPhe.vue'),
  },
  {
    path: '/size-your-system/boilerwhs',
    name: 'Sysboilerwhs',
    component: () => import('../views/SysBoiler.vue'),
  },
  {
    path: '/size-your-system/egc',
    name: 'Sysegc',
    component: () => import('../views/SysEgc.vue'),
  },
  {
    path: '/size-your-system/framo',
    name: 'Sysframo',
    component: () => import('../views/SysFramo.vue'),
  },  
  /* 2023 */
  {
    path: '/size-your-system/fc',
    name: 'Sysfc',
    component: () => import('../views/SysFc.vue'),
  },
  {
    path: '/size-your-system/fal',
    name: 'Sysfal',
    component: () => import('../views/SysFal.vue'),
  },
  {
    path: '/size-your-system/orc',
    name: 'Sysorc',
    component: () => import('../views/SysOrc.vue'),
  },
  {
    path: '/size-your-system/stormgeo',
    name: 'Sysstormgeo',
    component: () => import('../views/SysStormGeo.vue'),
  },
  {
    path: '/size-your-system/scanjet',
    name: 'Sysscanjet',
    component: () => import('../views/SysScanjet.vue'),
  },
  {
    path: '/size-your-system/oceanbird',
    name: 'Sysoceanbird',
    component: () => import('../views/SysOceanbird.vue'),
  },
  /* fine 2023 */
  {
    path: '/size-your-system',
    name: 'Size-your-system',
    component: () => import('../views/Size-your-system.vue'),
  },
  {
    path: '/marine-contacts',
    name: 'ContactsMarine',
    component: () => import('../views/ContactsMarine.vue'),
  },
  {
    path: '/energy-contacts',
    name: 'ContactsEnergy',
    component: () => import('../views/ContactsEnergy.vue'),
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/ContactsThanks.vue'),
  }
];

const router = new VueRouter({
  routes,
});

export default router;
