import Vue from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Axios from 'axios';
import _ from 'lodash';
import App from './App.vue';
import router from './router';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap-vue/dist/bootstrap-vue.css';

// css custom for Alfa Laval
import '@/assets/css/variables.css';
import '@/assets/css/typography.css';
import '@/assets/css/uLayouts.css';
import '@/assets/css/utilities.css';
import '@/assets/css/banner.css';
import '@/assets/css/breadcrumbs.css';
import '@/assets/css/card.css';
import '@/assets/css/cardList.css';
import '@/assets/css/collapse.css';
import '@/assets/css/footer.css';
import '@/assets/css/form.css';
import '@/assets/css/header.css';
import '@/assets/css/installApp.css';
import '@/assets/css/navbar.css';
import '@/assets/css/pageTitle.css';
import '@/assets/css/product.css';
import '@/assets/css/size-your-system.css';
import './registerServiceWorker';
import store from './store';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$http = Axios;
/* eslint-disable no-underscore-dangle */
Vue.prototype.$_ = _;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
