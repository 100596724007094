<template>
  <section>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/alfalaval.jpg') + ')',
        }"
      >
        <div class="containerLarge py-5 px-2 p-sm-3 p-md-3 p-lg-4 p-xl-4">
          <div
            class="bannerImage-card p-2 p-sm-3 p-md-3 p-lg-4 p-xl-5 
            text-center col-md-12">
            <h1>Getting Excellence</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="cardList mt-4 mt-md-5 mt-lg-5 pt-lg-3">
      <div class="containerLarge">
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mb-3 mb-md-5">
            <p class="lead text-center">
              Alfa Laval is a leading global provider of specialized products
              and engineering solutions based on its key technologies of heat
              transfer, separation and fluid handling. The company’s equipment,
              systems and services are dedicated to assisting customers in
              optimizing the performance of their processes.
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
            <div class="cardImage" @click="toGlobal('/marine', 'marine')">
              <div
                class="cardBox"
                :style="{
                  'background-image':
                    'url(' +
                    require('../assets/img/banner/navi.jpg') +
                    ')',
                }"
              ></div>
              <div class="cardContainer">
                <div class="cardContent">
                  <h2 class="cardContent-title">Marine</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
            <div class="cardImage" @click="toGlobal('/energy', 'energy')">
              <div
                class="cardBox"
                :style="{
                  'background-image':
                    'url(' +
                    require('../assets/img/banner/energy.jpg') +
                    ')',
                }"
              ></div>
              <div class="cardContainer" >
                <div class="cardContent">
                  <h2 class="cardContent-title">Energy</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
  },

  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },
  }
}; 
</script>
